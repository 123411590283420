@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Poppins:wght@200;300;600&display=swap');
@import './assets/mixins';

@font-face {
  font-family: 'WindSongMed';
  src: url('./assets/fonts/WindSongMed.ttf');
  src: url('./assets/fonts/WindSongMed.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/WindSongMed.otf'),
       url('./assets/fonts/WindSongMed.woff') format('woff'),
       url('./assets/fonts/WindSongMed.woff2') format('woff2');
}

$white:#fff;
$black:#000;
$d8:#d8d8d8;
$dk:#0c0e19;
$blue1:#6bcbdf;
$blue2:#2d83a6;
$blue3:#d1f6fe;
$sand:#F6F1EA;
$play:'Playfair Display', serif;
$emph:'WindSongMed';
$pop:'Poppins', sans-serif;

html{background-color:$dk;}

.inside, .insideAlt, .medDef, .med2Def, .med3Def, .med4Def, .insideXl, .insideN, .insideNrw, .insideTw, .custWrap{@include center-block();width:90%;}
.inside, .insideAlt, .insideXl, .insideN{max-width:1700px;}
.insideXl{width:95%;}
.inside{width:85%;}
.insideAlt{width:80%;}
.insideTw{max-width:1200px;}
.medDef{max-width:1080px;}
.med2Def{max-width:940px;}
.med3Def{max-width:850px;}
.med4Def{max-width:650px;}
@media screen and (min-width:823px){.insideNrw{max-width:820px;}}
@include bp(tb){.medDef, .med2Def, .med3Def, .med4Def, .insideNrw, .insideTw, .custWrap{width:92%;}}

.inzWrap{@include sec-default();}
img{max-width:100%;height:auto;display:block;}


.inzVideo{@include sec-default();position:relative;z-index:0;height:100vh;overflow:hidden;background-color:$dk;
  .fullVideo{width:100%;height:100vh;object-fit:cover;}
  .vidText{width:80%;max-width:680px;@include center-block();position:relative;z-index:1;text-align:center;}
  .vidStill{display:none;}
  .logo{width:250px;@include center-block();margin-bottom:30px;}

  h1,h2,h3,h4,a{color:$white;}
  h1{font-size:40px;font-family:$play;text-transform:uppercase;text-shadow:0px 1px 2px rgba($black,.5);line-height:1.5em;letter-spacing:2px;
    margin-top:0;
    br{display:none;}
  }
  h2{font-size:100px;font-family:$emph;text-shadow:0px 1px 2px rgba($blue1,.9);line-height:.65em;
    span{font-family:$pop;font-size:20px;text-shadow:none;}
  }
  h3{font-size:30px;font-family:$play;font-weight:600;text-transform:uppercase;letter-spacing:5px;
    background-color:$white;color:$dk;max-width:350px;padding:3px 0 5px;@include center-block();text-align: center;
  }
  h4{margin-top:20px;
    // display:none;
    a{margin:0 10px;font-size:17px;font-weight:normal;text-transform: uppercase;}
    a.current{font-weight: bold;text-decoration: underline;}
  }
  

  @include bp(mu){
    .vidOverlay{position:absolute;width:100%;height:100%;left:0;top:0;right:0;bottom:0;
      display:flex;align-items:center;justify-content:center;// background-color:rgba($black,.2);
    }
  }

  @include bp(br){
    h1{font-size:35px;}
    h2{font-size:90px;}
    h3{font-size:25px;}
  }

  @include bp(og){height:100%;overflow:visible;
    .fullVideo{display:none;}
    .vidOverlay{@include sec-default();position:relative;margin:70px 0 50px;}
    .vidStill{@include sec-default();}

    h1{font-size:25px;}
    h2{font-size:65px;
      span{font-size:17px;}
    }
    h3{font-size:20px;}
    h1 br{display:block;}
    h4 a{font-size:16px;}
  }
  
  @include bp(oc){
    .vidOverlay{margin:50px 0 20px;}
    .vidText{width:90%;}
    h1 br{display:none;}
    h1{font-size:19px;margin-top:20px;}
    h2{font-size:60px;margin-bottom:0px; span{font-size:15px;}}
    h3{font-size:17px;margin-top:30px;letter-spacing:3px;}
    h4{text-align:center;
      a{font-size:15px;max-width:110px;display:inline-block;margin:0 7px 10px;}//margin:0 6px 6px;}
    }
  } 

  @include bp(fi){
    // .vidOverlay{margin:50px 0 20px;}
    // .vidText{width:90%;}
    h1{font-size:18px;}//margin-top:20px;}
    h2{font-size:55px;}//margin-bottom:0px; span{font-size:15px;}}
    // h3{font-size:17px;}//margin-top:30px;}
  } 
}


@include bp(mu){
  // .Home1 .vidText{float:left;margin-left:8%;}
  .Home1 .vidText{margin-top:-280px;}

  .Home1b .vidText{margin-top:-220px;}
  .Home1c .vidText{margin-top:-280px;}
}



.inzTour{@include sec-default();position:relative;z-index:0; background-color:$white;padding-bottom:80px;//margin-top:-100px;
  .imgGroup{display:inline-block;width:54%;position:relative;margin-top:-100px;
    img{box-shadow:0px 10px 30px rgba($dk,.4);  position:relative;z-index:1;}

    &::after{@include abs();border:3px solid $blue1;width:35%;height:150px;bottom:-30px;}
  }
  .g1{float:left;margin-left:-5%;
    &::after{left:-75px;}
  }
  .g2{float:right;margin-right:-5%;text-align:right;
    &::after{right:-75px;}
  }


  p{font-size:20px;font-weight:bold;font-family:$emph;color:$dk;font-family:$play;text-transform:uppercase;letter-spacing:1px;margin:30px 0 0;background-color:$white;padding:4px 0;
    span{@include rotate(90deg);vertical-align:top;margin-top:5px;}
  }
  a:hover{text-decoration:none;}
  a p:hover{color:rgba($dk,.6);}

  @include bp(tl){
    .g1::after{left:-55px;}
    .g2::after{right:-55px;}
  }

  @include bp(tx){
    .imgGroup{width:50%;}
    .g1{margin-left:-1%;}
    .g2{margin-right:-1%;}
    .g1::after{left:-7%;}
    .g2::after{right:-7%;}
  }

  @include bp(tb){
    .imgGroup{width:49%;
      &::after{width:60%;}
    }
    .g1{margin-left:0;}
    .g2{margin-right:0;}
    .g1::after{left:-5%;}
    .g2::after{right:-5%;}

    p{text-align:center;}
  }

  @include bp(og){padding-top:60px;//margin-top:0px;
    .imgGroup{margin-top:0px;}
    p{font-size:18px;}
  }

  @include bp(oc){padding:30px 0 50px;
    .medDef{width:85%;max-width:450px;}
    .imgGroup{width:100%;
      &::after{bottom:-15px;width:50%;}
    }
    .g1{margin-bottom:50px;}
    .g1::after{left:auto;}
    .g1::after,.g2::after{right:-7%;}
    p{font-size:17px;margin-top:20px;}
  }

  @include bp(fi){.g1::after,.g2::after{right:-5%;}}
}